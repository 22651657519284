import * as Sentry from "@sentry/browser";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    ignoreErrors: [
      // Common Sentry Ignore Rules: ResizeObserver
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",

      // Common Sentry Ignore Rules: Non-Error promise rejection captured
      "Non-Error promise rejection captured",
    ],
  });
  Sentry.configureScope((scope) => {
    scope.setTag("build", BUILD_HASH);
  });
}
